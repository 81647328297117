import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import {PROJECTS} from "../saas";

const Eucleia = () => {


  return (
    <TechSoftLayout
      title="Eucleia"
      //bannerImage="ragazzo-al-portatile-connessione-wifi"
      descriptionType="onTheLeft"
      metaDescription="Eucleia software in cloudper migliorare la tua reputazione online e abbattere di oltre il 70% le recensioni negative. Incrementa la brand reputation!"
      PROJECTS={PROJECTS}
      description={`Eucleia è un software in cloud che consente di migliorare la tua reputazione online e di abbattere di oltre il 70% le recensioni negative.
      In che modo? Attraverso l’invio al tuo cliente di mail pre, during e post stay, sarai così in grado di risolvere le problematiche prima che lasci la tua struttura.`}
      contents={[{
        text: `Lo sapevi che la soddisfazione del tuo cliente genera pubblicità?
        Sin dal primo click crea con l’utente un rapporto privilegiato.
        Mission del tuo hotel: un ospite soddisfatto! 

        Un sicuro aumento della Brand Reputation in rete ed un conseguente
        incremento delle visite al sito web o alla pagina social collegata,
        generato da un accrescimento delle recensioni positive, che inoltre,
        favorirà un incremento delle prenotazioni.`,
        image: "eucleia-logo"
        },
        {
        text: `In particolare per le strutture ricettive, grazie all’integrazione 
        con Book@Me potrai gestire la Brand Reputation, offrendo ai tuoi clienti 
        un servizio di accoglienza dal momento della prenotazione sino al check-out. 

        Il 93% dei viaggiatori afferma che le recensioni hanno un effetto
        fondamentale nella loro decisione in fase di prenotazione in hotel.
        Il 79% ha rinunciato ad un acquisto online a causa dei commenti negativi.`,
        image: "eucleia-screen",
        linkText:"Vai al sito",
        to:"https://eucleia.app/"
        }]}
          // firstImage={"cau/phone1.png"}
          // secondImage={"cau/phone2.png"}
    />
  )
}

export default Eucleia
